.video-card {
  position: relative;
  padding: 6px;

  @include media('<md') {
    width: 100%;
  }

  &__image-container {
    width: 100%;
    position: relative;
    height: 0px;
    padding-bottom: 56%;
    overflow: hidden;
    margin-bottom: 15px;
    display: block;
  }
  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: auto;
    left: 0;
  }
  &__play {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__play-icon {
    font-size: 44px;
  }
  &__play-text {
    font-size: 16px;
    line-height: 22px;
    margin-left: 9px;
    white-space: nowrap;
    color: $light;
  }
  &__title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: $dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__description {
    font-size: 14px;
    line-height: 22px;
    color: #74767D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.modal-video-container {
  position: relative;
  margin-bottom: 30px;
}