.share-profile-container {
  padding-top: 32px;
  padding-bottom: 18px;

  @include media('<md') {
    padding-top: 0;
  }
}
.share-profile-agreement-text {

  a {
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

.row {

  &--share-profile {
    align-items: flex-start;
    margin-bottom: 45px;
  }
}
.col {

  &--share-profile {
    padding-right: 65px;

    @include media('<xl') {
      padding-right: 12px;
    }

    &:last-child {
      padding-left: 65px;
      padding-right: 12px;
      border-left: 1px dashed #C5C5C5;

      @include media('<xl') {
        padding-left: 12px;
        border-left: none;
      }

      &:before {

        @include media('<md') {
          display: block;
          position: relative;
          content: "";
          padding-bottom: 23px;
          border-top: 1px dashed #C5C5C5;
        }
      }
    }

    .select-options-container {
      min-height: 90px;
    }
  }
}

.facility-item {
  border: 1px solid $gray-border;
  border-radius: 8px;
  position: relative;
  padding: 15px 21px 15px 25px;
  margin-bottom: 35px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
  }

  &__data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -21px 0 -25px;
  }

  &__data-item {
    margin: 0 25px 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: $black;
  }

  .block-title {
    margin-bottom: 0px;
  }
}
.guest-admin-form {

  .field--input {
    max-width: 422px;
  }
}

.guest-admin-card {
  border: 1px solid $gray-border;
  border-radius: 10px;
  padding: 20px 27px ;
  margin-bottom: 44px;

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 5px;
    }
  }

  &__item-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: $text-color;
    margin-bottom: 10px;
  }

  &__item-value {
    font-size: 18px;
    line-height: 22px;
    color: $text-color;
  }
}

.facility-requests {}
.facility-requests-item {
  background: #F6F0FD;
  border-radius: 8px;
  margin-bottom: 13px;
  padding: 15px 30px;

  @include media('<md') {
    padding: 10px 20px 20px 20px;
  }

  p {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 11px;

    @include media('<md') {
      font-size: 14px;
    }
  }
}

.team-members-list {
  margin-bottom: 50px;
}
.team-member-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 11px 0 13px 0;
  border-bottom: 1px solid #CCCCCC;

  &__name {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 2px;
  }
  &__status {
    font-size: 14px;
    color: #A8A8A8;
    margin-left: 5px;
  }
  &__email {
    font-size: 13px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 7px;
  }

  .btn {
    margin-bottom: 0;
  }
}