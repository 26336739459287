.react-player {}

.music-player {
  $this: &;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: $player-bg;

  @include media('<xl') {
    overflow: visible;
  }

  @include media('<md') {}

  &__node {
    display: none;
  }

  // track details
  &__track-container {
    position: relative;
    padding: 28px 28px 28px 42px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include media('<xl') {
      padding: 26px 24px;
    }

    @include media('<md') {
      padding: 14px;
    }
  }
  &__track-cover {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 139px;
    margin-right: 37px;
    height: 139px;
    position: relative;

    img {}

    @include media('<md') {
      flex-basis: 64px;
      margin-right: 13px;
      height: 64px;
    }
  }
  &__track-cover-text {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    background-color: #92297B;
  }
  &__track-details {
    display: flex;
    flex-direction: column;
    flex-basis: calc(100% - 176px);
    width: calc(100% - 176px);
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;

    @include media('<md') {
      flex-basis: calc(100% - 77px);
      width: calc(100% - 77px);
    }
  }
  &__track-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 11px;

    @include media('<md') {
      margin-bottom: 5px;
    }
  }
  &__track-label {}
  &__track-header-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__action-button {
    font-size: 16px;
    line-height: 22px;
    color: $light;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 8px;

      @include media('<md') {
        margin-right: 6px;
      }
    }

    @include media('<md') {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__like-button {
    padding: 1px 5px;

    .icon {
      font-size: 20px;

      path {
        stroke: $light;
      }

      @include media('<md') {
        font-size: 14px;
      }

      &--active {
        path {
          stroke: none;
        }
      }
    }
  }
  &__trash-button {
    margin-left: 27px;

    .icon {
      font-size: 16px;
      fill: $light;
    }

    @include media('<md') {
      display: none;
    }
  }
  &__track-title {
    font-size: 32px;
    line-height: 39px;
    color: $light;
    font-weight: 700;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    @include media('<md') {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__track-author {
    font-size: 16px;
    line-height: 22px;
    color: $light;
    font-weight: 500;
    max-width: 100%;
    margin-bottom: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    @include media('<md') {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      padding-right: 65px;
    }
  }
  &__track-data {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: $light;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__separator {
      width: 6px;
      height: 6px;
      border-radius: 1px;
      background-color: $light;
      margin: 0 10px;

      @include media('<md') {
        display: none;
      }
    }

    @include media('<md') {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &__track-year {
    @include media('<md') {
      display: none;
    }
  }
  &__track-duration {

    @include media('<md') {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 2px;
      font-size: 12px;
      line-height: 16px;
      color: $player-bg;
      font-weight: bold;
      padding: 1px 5px 0;
    }
  }
  // track details

  &__progress-container {
    position: relative;
    height: 4px;
  }

  &__progress {
  }

  &__controls-container {
    position: relative;
    padding: 14px 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media('<xl') {
      padding: 10px 24px 12px;
    }

    @include media('<md') {
      justify-content: center;
    }
  }
  &__label {
    font-size: 14px;
    line-height: 22px;
    color: $light;
    font-weight: 300;

    @include media('<md') {
      font-size: 12px;
    }
  }

  &__duration {
    flex: 1 0 auto;
    align-self: center;

    @include media('<md') {
      display: none;
    }
  }
  &__duration-title {
    display: block;
  }
  &__duration-number {
    font-weight: 700;
    display: block;
  }
  &__controls {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 0 1 auto;

    #{$this}__button-container {
      @include media('<xl') {
        margin: 0 6px;
        width: 58px;
      }
    }
  }
  &__volume-controls {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media('<md') {
      display: none;
    }
  }
  &__volume {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  &__time {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: $light;
    padding: 0 7px;

    @include media('<md') {
      display: none;
    }
  }
  &__button-container {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;

    &--mute {
      margin-left: 17px;

      @include media('<xl') {
        margin-left: 10px;
      }
    }

    &--volume {
      @include media('<xl') {
        display: none;
      }
    }
  }
  &__button {
    margin: 0 24px;
    cursor: pointer;

    @include media('<xl') {
      margin: 0 0px;
    }

    @include media('<md') {
      margin: 0 16px;
    }

    &--shuffle {
      font-size: 24px;
      width: 24px;
      height: 19px;
      margin-bottom: 22px;

      @include media('<xl') {
        font-size: 20px;
        width: 20px;
        height: 15px;
        margin-bottom: 18px;
      }

      @include media('<md') {
        font-size: 18px;
        width: 18px;
        height: 14px;
        margin-bottom: 14px;
      }
    }
    &--repeat {
      font-size: 24px;
      width: 24px;
      height: 19px;
      margin-bottom: 22px;

      @include media('<xl') {
        font-size: 18px;
        width: 18px;
        height: 17px;
        margin-bottom: 18px;
      }
      @include media('<md') {
        font-size: 17px;
        width: 17px;
        height: 15px;
        margin-bottom: 14px;
      }
    }
    &--previous {
      font-size: 22px;
      width: 22px;
      height: 20px;
      margin-bottom: 22px;

      @include media('<xl') {
        font-size: 18px;
        width: 18px;
        height: 17px;
        margin-bottom: 18px;
      }
      @include media('<md') {
        font-size: 17px;
        width: 17px;
        height: 15px;
        margin-bottom: 14px;
      }
    }
    &--next {
      font-size: 22px;
      width: 22px;
      height: 20px;
      margin-bottom: 22px;

      @include media('<xl') {
        font-size: 18px;
        width: 18px;
        height: 17px;
        margin-bottom: 18px;
      }
      @include media('<md') {
        font-size: 17px;
        width: 17px;
        height: 15px;
        margin-bottom: 14px;
      }
    }
    &--play {
      font-size: 52px;
      width: 52px;
      height: 52px;
      margin-bottom: 6px;

      @include media('<xl') {
        font-size: 42px;
        width: 42px;
        height: 42px;
        margin-bottom: 4px;
      }
      @include media('<md') {
        font-size: 42px;
        width: 42px;
        height: 42px;
        margin-bottom: 0px;
      }
    }
    &--volume {
      font-size: 36px;
      width: 36px;
      height: 34px;
      margin: 0 8px 4px;

      &:active {
        background-color: #fff;
        border-radius: 50%;

        .icon {
          stroke: #333333;
        }
      }
    }
    &--mute {
      font-size: 34px;
      width: 34px;
      height: 34px;
      margin: 16px 0 4px;

      @include media('<xl') {
        margin: 8px 0 2px;
      }
    }

    &--active {
      color: #B176EF;

      & + #{$this}__label {
        color: #B176EF;
      }

      &.music-player__button--play, &.music-player__button--mute {
        & + #{$this}__label {
          color: $light;
        }
      }
    }
  }

  &--small {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;

    @include media('<md') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
    }

    #{$this}__track-header {
      @include media('<md') {
        display: none;
      }
    }
    #{$this}__track-container {
      flex-basis: 290px;
      width: 290px;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;

      @include media('<xl') {
        flex-basis: 280px;
        width: 280px;
        flex-shrink: 1;
      }
      @include media('<md') {
        flex-basis: auto;
        padding: 14px 85px 10px 14px;
        width: 100%;
        box-sizing: border-box;
      }
    }
    #{$this}__track-details {
      flex-grow: 1;
      flex-basis: auto;
    }
    #{$this}__track-cover {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 87px;
      margin-right: 10px;
      height: 87px;

      @include media('<xl') {
        flex-basis: 70px;
        height: 70px;
      }
      @include media('<md') {
        flex-basis: 42px;
        margin-right: 9px;
        height: 42px;
      }
    }
    #{$this}__track-title {
      font-size: 18px;
      line-height: 22px;

      @include media('<md') {
        font-size: 16px;
        line-height: 20px;
      }
    }
    #{$this}__track-author {
      font-size: 14px;
      line-height: 22px;

      @include media('<md') {
        font-size: 12px;
        line-height: 22px;
      }
    }
    #{$this}__controls-container {
      padding: 0;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 169px;

      @include media('<xl') {
        flex-basis: auto;
      }
      @include media('<md') {
        display: none;
        flex-basis: 0;
      }
    }
    #{$this}__middle {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 565px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media('<md') {
        max-width: none;
        margin: 0 3px;
        width: calc(100% - 6px);
      }
    }
    #{$this}__controls {
      margin-bottom: 10px;

      @include media('<md') {
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
      }

      #{$this}__button-container {
        @include media('<md') {
          margin: 0 0px;
          width: auto;
        }
      }
    }
    #{$this}__progress-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
    }
    #{$this}__button-container {

      @include media('<md') {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          display: none;
        }
      }
    }
    #{$this}__button {
      @include media('<md') {
        margin: 0 8px;
        font-size: 27px;
        width: 27px;
        height: 27px;
      }
    }
    #{$this}__button--play {
    }
    #{$this}__button--next {
      @include media('<md') {
        font-size: 27px;
      }
    }
    #{$this}__button--mute{
      @include media('<xl') {
        margin: 0 0 2px;
      }
    }
  }
}

// input range
input[type=range] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  height: 4px;
  background: $light;
  transition: background 0.2s ease;
  cursor: pointer;
  display: block;

  @include media('<md'){
    border-radius: 0 0 10px 10px;
    margin-bottom: -2px;
    background: #B9B9B9;
  }

  &:hover {
    &::-webkit-slider-thumb {
      opacity: 1;
    }
    &::-moz-range-thumb {
      opacity: 1;
    }
    &::-ms-thumb {
      opacity: 1;
    }
  }
}
input[type=range]:focus {
  outline: none;
}
//input[type=range]::-webkit-slider-runnable-track {
//width: 100%;
//height: 4px;
//cursor: pointer;
//background: $light;
//}
//input[type=range]:focus::-webkit-slider-runnable-track {
//background: $brand-accent;
//}
input[type=range]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: $light;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transition: 0.3s;
  opacity: 0;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: linear-gradient(#7A23D3,#7A23D3) 0/var(--sx) 100% no-repeat, #efefef;
}

input[type=range]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: $light;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 3px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $brand-accent;
}
input[type=range]::-ms-fill-upper {
  background: $light;
}
input[type=range]::-ms-thumb {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: $light;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
}
input[type=range]:focus::-ms-fill-lower {
}
input[type=range]:focus::-ms-fill-upper {
}
// input range
