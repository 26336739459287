.modal {
  position: absolute;
  width: 100%;
  max-width: 776px;
  padding: 30px 40px 24px 40px;
  max-height: 100%;

  @include media('<md') {
    padding: 39px 22px 22px;
    width: calc(100% - 44px);
  }

  &__title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: $text-color;
    margin-bottom: 10px;

    @include media('<md') {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__close-btn {
    position: absolute;
    right: 15px;
    top: 8px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #1e1e1e;

    @include media('<md') {
      right: 0;
      top: 0;
    }
  }
  &__close-btn-icon {
    font-size: 21px;
    margin-right: 3px;
  }
  &__text {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 15px;

    @include media('<md') {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &--extra-wide {
    max-width: 1127px;
  }

  &--wide {
    max-width: 1037px;
  }

  &--mood-size {
    max-width: 1117px;
  }

  &--xs {
    max-width: 591px;
  }

  &--demo-complete-size {
    max-width: 1197px;
    overflow: hidden;

    @include media('<xl') {
      width: calc(100% - 44px);
    }

    .modal__title {
      margin: 0px;
    }

    .demo-complete-cards-wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 860px;
      width: 100%;
      margin: auto;

      .demo-complete-card {
        max-width: 420px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.4px solid #bdbdbd;
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 38px 0 0;

        @include media('<iPad') {
          margin-bottom: 10px;
        }

        .image {
          margin-bottom: 18px;
          width: 53px !important;
          height: 44px !important;
        }

        p {
          font-family: $font-family-base;
          font-size: 18px;
          line-height: 32px;
          margin: 0;
        }
      }
    }

    .vera-video {
      max-width: 842px;
      width: 100%;
      height: 474px;
      margin: 0 auto 73px;
    }

    .footer-text {
      margin: 56px 0 0;
      text-align: center;
      font-family: $font-family-base;
      font-size: 18px;
      line-height: 32px;

      @include media('<iPad') {
        margin: 36px 0 0;
      }
    }

    .footer-link {
      display: flex;
      justify-content: center;
      margin-bottom: 31px;
    }

    .purple-underlined {
      font-family: $font-family-base;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      text-decoration: underline;
      color: $brand-accent;

      &.card {
        margin-bottom: 45px;

        @include media('<iPad') {
          margin-bottom: 30px;
        }
      }

      @include media('<md') {
        font-size: 16px;
      }
    }
  }

  &--cognitive-decline {
    max-width: 756px;

    p.modal__text {
      max-width: 662px;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 26px;
    }

    .btn {
      width: 122px;
    }
  }

  &--tooltip-modal {
    max-width: 270px;
    padding: 20px 15px 0;
    overflow: hidden;

    .modal__close-btn {
      display: none;
    }

    h1 {
      font-family: $font-family-base;
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-family: $font-family-base;
      font-size: 15px;
      line-height: 30px;
      margin: 0 0;
      text-align: center;

      &.modal__text {
        margin-bottom: 26px;
      }

      &.align-left {
        text-align: left;
      }
    }

    .okay-btn {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::before {
        content: '';
        border-top: 1px solid rgba(6, 60, 67, 0.36);
        width: 270px;
        position: absolute;
        top: 0;
      }

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        color: $brand-accent;
      }
    }
  }

  &--rate {
    max-width: 721px;
    overflow: hidden;

    .btn {
      width: 140px;
    }

    .rate-wrapper {
      display: flex;
      max-width: 310px;
      justify-content: space-between;
      margin: 41px auto 40px;
    }
  }

  &--small {
    max-width: 655px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      display: none;
    }

    .image {
      max-width: 417px;
      width: 100%;
      height: auto;
    }

    p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 37px;
      color: $text-color;
      max-width: 555px;
      width: 100%;
      text-align: center;

      @include media('<sm') {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .progress-percent {
      color: $brand-accent;
      font-weight: 600;
      margin: 10px 0 0;
    }

    .progress {
      max-width: 273px;
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background-color: rgb(219, 219, 219);
      margin-bottom: 32px;

      .progress-line {
        width: 0;
        height: 10px;
        border-radius: 5px;
        background-color: $brand-accent;
        transition: 'width 1s ease-in-out';
      }

      .progress-animation {
        width: 0;
        height: 10px;
        border-radius: 5px;
        background-color: $brand-accent;
        animation: progress-line 50s linear forwards;
      }

      .progress-animation-refresh {
        width: 0;
        height: 10px;
        border-radius: 5px;
        background-color: $brand-accent;
        animation: progress-line 15s linear forwards;
      }

      @keyframes progress-line {
        0% {
          width: 0%;
        }

        100% {
          width: 99%;
        }
      }
    }
  }
  &--add-profiles-modal {
    width: calc(100% - 44px);

    .add-profiles-cards-container {
      margin: 60px auto 46px;
      max-width: 790px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &--invite-vera-user-modal {
    .buttons-wrapper {
      max-width: 287px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 130px;
      }
    }
  }

  .table {
    @include media('<md') {
      margin-left: 0;
      margin-right: 0;
    }

    &__row {
      @include media('<md') {
        padding: 11px 124px 11px 0px;
      }
    }

    &__cell {
      @include media('<md') {
        padding: 0 0;
      }
    }
  }

  .onboarding-app {
    margin: 20px 21px 20px 26px;

    @include media('<md') {
      margin: 28px 0 21px;
    }

    &__logo {
      max-width: 164px;
      max-height: 154px;
      margin-right: 10px;
      @include media('<md') {
        max-width: 120px;
        max-height: 99px;
        margin: 18px 0;
      }
    }

    &__content {
      display: flex;
      justify-content: stretch;
      align-items: center;
      flex-direction: row;

      @include media('<md') {
        flex-direction: column;
      }
    }

    &__markets {
      display: flex;
      justify-content: stretch;
      align-items: center;
      flex-direction: column;

      > a {
        margin: 10px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 20px;
        text-decoration: unset;

        @include media('<md') {
          display: flex;
          width: 100%;
          margin: 10px 13px;
          justify-content: center;
        }
      }

      img {
        width: 215px;
        &.google {
          transform: scale(1.08);
        }
      }
    }

    &__button {
      border-color: #CFCFCF;
      color: #1D1D1D;

      @include media('<md') {
        max-width: 215px;
      }
    }

    .modal__title {
      margin-bottom: 35px;

      @include media('<md') {
        text-align: center;
      }
    }

    .modal__text {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .trial-reminder {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;

    &__image {
      max-width: 114px;
      max-height: 127px;
      margin: 22px 0;

      @include media('<md') {
        margin: 25px 0 58px;
      }
    }
  }
}
