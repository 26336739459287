.login-wrapper {
  display: flex;
  width: 100%;
  flex-grow: 1;
  max-width: 1440px;

  @include media('<sm') {
    padding: 0px 22px;
  }
  @media (max-width: 946px) {
    display: flex;
    justify-content: center;
  }

  .left-block {
    padding: 60px 70px 30px;
    width: auto;
    background-color: white;
    display: flex;
    justify-content: flex-end;


    @include media('<md-lg') {
      padding: 50px 0 0;
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 1360px) {
      padding: 50px 40px 30px;
    }

    @include media('<sm') {
      padding: 25px 0;
      width: 100%;
    }

    .login-form-container {
      width: 441px;
      position: relative;

      @include media('<sm') {
        max-width: 441px;
        width: 100%;
      }
      h1 {
        margin-top: 17px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: $text-color;

        @include media('<sm') {
          font-size: 30px;
        }
      }
      .join-now-block {
        display: flex;
        margin: 7px 0 32px 0;
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $text-color;
        }
        .join-now {
          text-decoration: none;
          font-family: $font-family-base;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: $brand-accent;
          margin-left: 4px;
          cursor: pointer;

          @include media('<sm') {
            margin-left: 20px;
          }
        }
      }
      .field {
        padding-bottom: 20px;
        &__label {
          font-size: 18px;
          font-weight: normal;
        }
        &__input {
          height: 45px;
          font-size: 18px;
        }
      }
      .error-message {
        font-size: 16px;
        color: red;
        // position: absolute;
        top: 136px;
      }
      .input-email {
        padding-bottom: 35px;
      }
      .input-password {
        @include media('<md') {
          padding-bottom: 22px;
        }
      }
      .login-buttons {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        
        @include media('<md') {
          .btn {
            width: 176px;
          }
        }
        @media (max-width: 946px) {
          .forgot-password {
            display: none;
          }
        }

        .remember-me {
          padding-bottom: 0;

          .checkbox__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;

            @include media('<sm') {
              font-size: 14px;
            }
          }
        }
        .forgot-password {
          text-decoration: none;
          padding-top: 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 0;
          text-align: right;
          color: #7A23D3;

          @include media('<lg') {
            padding-top: 10px;
          }
        }
      }
      .login-button {
        width: 100%;
        height: 50px;
        font-size: 18px;
        line-height: 22px;
        margin: 56px 0 10px;
        letter-spacing: 0.1em;

        @media (max-width: 946px) {
          margin: 30px 0;
        }
      }
      .forgot-password-mobile {
        display: none;
        margin-bottom: 30px;

        @media (max-width: 946px) {
          display: flex;
          justify-content: center;
        }
        .forgot-password {
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
        }
      }
      .social-btn {
        min-width: 103px;
        width: 141px;

        @media (max-width: 480px) {
          width: 120px;
        }
        @media (max-width: 410px) {
          width: 103px;
        }
      }
      .switch-vera-type {
        margin-top: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        span {
          color: $brand-accent;
          font-weight: bold;
          cursor: pointer;
        }

        &--mobile {
          margin-top: 0px;
          margin-bottom: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .right-block {
    width: 100%;
    padding: 70px 50px 0 50px;
    display: flex;
    flex-direction: column;

    @media (max-width: 946px) {
      display: none;
    }


    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 50%;
      right: 15%;
      bottom: 20%;
      top: 20%;
      border-radius: 300px;
      filter: blur(130px);
      background-image: linear-gradient(0deg, rgba(211, 195, 228, 0.0692) 0%, rgba(211, 195, 228, 0.0692) 0.01%, rgba(83, 20, 149, 0.27) 72.92%);
    }

    

    .text-wrapper {
      width: 100%;

      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        color: $brand;
        margin-bottom: 8px;
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: $text-color-dark;
        margin: 0;
        max-width: 400px;


        &.text-gap {
          margin-bottom: 20px;
        }

        &.list-gap {
          margin: 4px 0;
        }

        @include media('<xl') {
          font-size: 16px;
          line-height: 24px;
          max-width: 400px;

        }
      }
    }

    .image-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1;
      padding-right: 100px;
      @include media('<xl') {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .image {
      max-width: 400px;

      @include media('<xl') {
        width: 350px;
        left: calc(50% - 200px);
      }
    }

    &--pro {
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 50%;
        right: 15%;
        bottom: 20%;
        top: 20%;
        border-radius: 300px;
        filter: blur(130px);
        background-image: linear-gradient(0deg, rgba(174, 238, 223, 0.19) 0%, rgba(11, 205, 159, 0.3) 73%);
      }


      .text-wrapper p {
        @include media('<xl') {
          max-width: 390px;
        }
      }
      .image {
        top: calc(50% - 150px);
        left: calc(50% - 220px);
        @include media('<xl') {
          left: calc(50% - 180px);
        }
      }
    }
  }

  &--pro {
    .login-form {
      margin-top: 40px;
    }
  }

  // fast update for errors
  .field__error {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}
.social-network-wrapper {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  img {
    max-width: 25px;
  }
  .social-network {
    max-width: 154px;
    width: 100%;
    height: 56px;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    &:nth-child(even) {
      margin: 0 10px;
    }
  }
}