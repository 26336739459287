*,
*:before,
*:after {
  box-sizing: border-box;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

html,
body {
  height: 100%;

  &.is-scroll-none {
    overflow: hidden;
    height: 100%;

    body {
      overflow: hidden;
      height: 100%;
    }
  }
}
html {}

// * {
// 	-webkit-user-select: none;  /* Chrome all / Safari all */
//   -moz-user-select: none;     /* Firefox all */
//   -ms-user-select: none;      /* IE 10+ */
//   user-select: none;          /* Likely future */
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: $body-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-weight: 400;
  line-height: 1.2;
  min-width: 320px;
  background: #FFF;

  &.js-block-scroll {
    overflow-y: hidden;
  }
}

th {
  font-weight: inherit;
}

// font default
//-----------------------------------------------------------------------------------
body,
input,
button,
select,
textarea {
  font-family: $font-family-base;
}

li {
  list-style-type: none;
}
button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    color: inherit;
    cursor: auto;
  }
}
// Common classes
//-----------------------------------------------------------------------------------
.clear {
  clear: both;
}

%clear-fix:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
.mb-30 {
  margin-bottom: 30px;
}
.app-body {
  position: relative;
  padding: 55px 0;
}
p {
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 18px;

  &.password-manage {
    margin-bottom: 45px;
  }

  &.password-manage-message {
    font-size: 16px;
    line-height: 32px;
    color: #0BCD9F;
    margin: 0;

    &--bottom {
      margin-bottom: 30px;
    }

    &--error {
      color: red;
    }
  }

  &.response-manage-message {
     font-weight: bold;
     font-size: 16px;
     line-height: 32px;
     color: #0BCD9F;
     margin-bottom: 20px;

     &--error {
       color: red;
     }
   }
}
a {
  color: $brand-accent;
}
ul {
  margin-bottom: 10px;

  li {
    color: $text-color;
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}
img {
  max-width: 100%;
}
h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;

  @include media('<md') {
    font-size: 24px;
    line-height: 29px;
  }
}
h2 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;

  @include media('<md') {
    font-size: 18px;
    line-height: 27px;
  }
}
h4 {}
hr {
  height: 1px;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: $gray-border;
  margin-bottom: 15px;
}
.font-regular {
  font-weight: 400;
}
.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.section-title {
  color: $text-color;
  font-size: 24px;
  margin-bottom: 10px;
}
.section-subtitle {
  color: $text-color;
  font-size: 20px;
  margin-bottom: 10px;
}

.full-height {
  min-height: 100%;
}

.table {
  $this: &;
  display: table;
  width: 100%;

  @include media('<md') {
    border-top: 1px solid #DDDDDD;
    display: block;
    margin: 0 -27px;
    width: auto;
  }

  &__header {
    display: table-header-group;

    @include media('<md') {
      display: none;
    }

    #{$this}__cell {
      font-size: 14px;
      line-height: 17px;
      color: #828282;
      text-transform: uppercase;
      font-weight: 500;
      padding: 5px 0;
    }

    &--filled {
      background-color: #F4F5F8;

      #{$this}__cell {
        border-bottom: none;
      }
    }
  }
  &__body {
    display: table-row-group;

    @include media('<md') {
      display: block;
    }
  }
  &__row {
    display: table-row;

    @include media('<md') {
      display: flex;
      padding: 11px 124px 11px 17px;
      border-bottom: 1px solid #DDDDDD;
      flex-direction: column;
      position: relative;
    }

    #{$this}__cell {
      &:first-child {
        padding-left: 20px;

        @include media('<md') {
          padding-left: 0;
        }
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }
  &__cell {
    display: table-cell;
    padding: 10px 12px 10px 0;
    border-bottom: 1px solid #DDDDDD;
    vertical-align: middle;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;

    @include media('<md') {
      display: flex;
      padding: 0 10px;
      border-bottom: none;
      font-size: 14px;
    }

    &--actions {
      width: 230px;

      @include media('<md') {
        width: 124px;
        position: absolute;
        right: 0px;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--cover {
      position: relative;
      width: 60px;

      @include media('<md') {
        display: none;
      }
    }

    &--pointer {
      cursor: pointer;
    }

    &--disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
  &__track-indicator {
    position: absolute;
    left: 0;
    right: 12px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;

    .icon {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
    }

    @include media('<md') {
      right: 10px;
    }
  }
  &__track-title {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 300px;

    &--is-playing {
      color: $brand-accent;
    }
  }
  &__track-artist-name {
    &--is-playing {
      color: $brand-accent;
    }
  }
  &__track-artist {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .icon--star {
    font-size: 14px;
    position: absolute;
    left: -20px;

    @include media('<md') {
      font-size: 12px;
      left: -16px;
    }
  }
  &__track-artist-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 150px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<md') {
      width: 100%;
      justify-content: flex-start;
    }
  }
  &__action {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: #333333;

    @include media('<md') {
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      margin-right: 0px;
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .icon {
      margin-right: 6px;

      @include media('<md') {
        margin-right: 0px;
        margin-bottom: 2px;
      }
    }
    .icon--like {
      font-size: 19px;

      @include media('<md') {
        font-size: 16px;
      }
    }
    .icon--trash {
      font-size: 16px;

      @include media('<md') {
        font-size: 13px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
    &:disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
  &__action-text {
    white-space: nowrap;
  }
  &__action-add-back {
    font-weight: 600;
    color: $brand-accent;
  }
  &__action-accepted {
    font-weight: 600;
    color: #2EA63A;
  }
  &__action-rejected {
    font-weight: 600;
    color: #D64242;
  }

  &--activity {

    #{$this}__cell {
      font-size: 14px;
      line-height: 17px;
    }

    #{$this}__header {
      #{$this}__cell {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  &--playlist, &--profiles {
    #{$this}__row {
      #{$this}__cell {
        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }

  &--team-members {
    margin-bottom: 50px;
  }

  &--invites {
    margin-bottom: 50px;

    @include media('<md') {
      margin-bottom: 35px;
    }
  }

  &--facilities {
    margin-bottom: 30px;
  }
}
.cover-container {
  display: flex;
  background-color: #92297B;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-weight: 700;
  position: relative;

  &--gray {
    background-color: #DFDFDF;
    color: #AEAEAE;
  }

  &--rounded {
    border-radius: 2px;
    overflow: hidden;
  }

  &--middle {
    height: 55px;
    width: 55px;
  }

  &--big {
    height: 62px;
    width: 62px;
  }
}
.cover-image {
  object-fit: cover;
  width: 100%;
  height: auto;

  &--disabled {
    filter: grayscale(100%);
  }
}

.block-title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8F8F8F;
  margin-bottom: 13px;
}

.error-message {
  color: red;
  font-size: 16px;
  line-height: 19px;
}

.latest-activity-item {
  position: relative;
  background-color: $light;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;

  &__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: $dark;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__id {
    font-size: 16px;
    color: #737373;
    margin-left: 10px;
  }

  &__action {
    font-size: 18px;
    line-height: 22px;
    color: $dark;
    margin-bottom: 8px;
  }

  &__date {
    font-size: 14px;
    line-height: 22px;
    color: #737373;
  }
}