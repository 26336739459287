.signup-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;

  @include media('<sm') {
    padding: 0px 22px;
  }
  @media (max-width: 946px) {
    display: flex;
    justify-content: center;
  }

  .left-block {
    padding: 60px 70px 25px;
    width: auto;
    background-color: white;
    display: flex;
    justify-content: flex-end;

    @include media('<md-lg') {
      padding: 50px 0 0;
      width: inherit;
      justify-content: center;
    }
    @media (max-width: 1360px) {
      padding: 50px 40px 25px;
    }
    @include media('<sm') {
      padding: 25px 0;
      width: 100%;
    }

    .signup-form-container {
      width: 441px;

      @include media('<sm') {
        max-width: 441px;
        width: 100%;
      }

      h1 {
        margin-top: 17px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: $text-color;

        @include media('<sm') {
          font-size: 30px;
        }
      }
      .join-now-block {
        display: flex;
        margin: 7px 0 10px 0;
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $text-color;
        }
        .join-now {
          text-decoration: none;
          font-family: $font-family-base;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: $brand-accent;
          margin-left: 4px;

          @include media('<sm') {
            margin-left: 20px;
          }
        }
      }
      .signup-form {
        position: relative;
        
        .error-message--email {
          position: absolute;
          top: 97px;
          left: 119px;

          @include media('<md') {
            top: 93px;
          }
        }
      }
      .input-email, .input-name {
        padding-bottom: 35px;
      }
      .input-password {
        @include media('<md') {
          padding-bottom: 0px;
        }
      }
      .field {
        padding-bottom: 25px;
        &__label {
          font-size: 18px;
          font-weight: normal;
        }
        &__input {
          height: 45px;
          font-size: 18px;
        }
        @media (max-width: 375px) {
          padding-bottom: 15px;
        }
      }
      .remember-me {
        margin-top: 5px;

        @include media('<lg') {
          padding-bottom: 10px;
        }
        .checkbox__title {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;

          @include media('<sm') {
            font-size: 14px;
          }
        }
      }
      .signup-button {
        width: 100%;
        height: 50px;
        font-size: 18px;
        line-height: 22px;
        margin: 20px 0;
        letter-spacing: 0.1em;

        @include media('<lg') {
          margin-top: 40px;
          margin-bottom: 10px;
        }
        @media (max-width: 375px) {
          margin: 10px 0;
        }
      }
      .social-btn {
        min-width: 103px;
        width: 141px;
        @media (max-width: 480px) {
          width: 120px;
        }
        @media (max-width: 410px) {
          width: 103px;
        }
      }
      .switch-vera-type {
        text-align: center;
        margin-top: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        span {
          color: $brand-accent;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .mobile-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-wrapper {
          width: 90%;
          h1 {
            text-align: center;
            font-weight: bold;
            font-size: 52px;
            line-height: 56px;
            color: $brand;
          }
          p {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin: 10px 0;
          }
          .thesises-wrapper {
            .thesis {
              display: flex;
              font-size: 14px;
              line-height: 32px;
              span {
                padding: 0;
              }
              svg {
                margin-top: 10px;
              }
            }
          }

          &.listener {
            margin-bottom: 60px;

            @media (max-width: 375px) {
              margin-bottom: 30px;
            }

            p {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
        .image {
          margin-top: 50px;
          margin-bottom: 50px;
          max-width: 125px;

          &.listener {
            max-width: 195px;
          }
        }
      }
    }
  }
  .right-block {
    width: 100%;
    position: relative;

    padding: 70px 50px 0 50px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 20%;
      right: 20%;
      bottom: 0;
      top: 25%;
      border-radius: 300px;
      filter: blur(130px);
      background-image: linear-gradient(0deg, rgba(211, 195, 228, 0.0692) 0%, rgba(211, 195, 228, 0.0692) 0.01%, rgba(83, 20, 149, 0.27) 72.92%);
    }

    .text-wrapper {
      width: 100%;

      

      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        color: $brand;
        margin-bottom: 8px;
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: $text-color-dark;
        margin: 0;
        max-width: 400px;


        @include media('<xl') {
          font-size: 16px;
          line-height: 24px;
          max-width: 400px;

        }
      }
    }

    .image {
      max-width: 400px;
      position: absolute;
      top: calc(50% - 100px);
      left: calc(50% - 250px);

      @include media('<xl') {
        width: 350px;
        left: calc(50% - 200px);
      }
    }

    @include media('<xl') {
      padding: 100px 47px;
    }
    @include media('<lg') {
      padding-left: 17px;
    }
    @media (max-width: 1360px) {
      padding-left: 15px;
    }
    @media (max-width: 946px) {
      display: none;
    }

    .billing-text {
      font-size: 14px;
      line-height: 23px;
      max-width: 340px;
      width: 100%;
      margin: 28px 0 0 65px;

      @include media('<xl') {
        margin: 24px 0 0;
        max-width: 224px;
        font-size: 12px;
      }
    }

  }

  // fast update for errors
  .field__error {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}