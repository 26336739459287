.field {
  $this: &;
  display: block;
  padding-bottom: 35px;

  @include media('<md') {
    padding-bottom: 14px;
  }

  &__container {
    position: relative;

    &--with-button {
      height: 50px;
      width: 100%;
      border: 1px solid $disabled;
      border-radius: $border-radius;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 481px;
    }
  }

  &__label {
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: $text-color;
    font-weight: 700;
    margin-bottom: 5px;

    @include media('<md') {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__input {
    height: 50px;
    width: 100%;
    border: 1px solid $disabled;
    border-radius: $border-radius;
    font-size: 20px;
    padding: 0 20px;

    @include media('<md') {
      font-size: 18px;
      line-height: 22px;
    }

    &--email {
      position: relative;
      z-index: 1;
      background-color: transparent;
    }

    &--with-button {
      border: none;
      height: 48px;
      width: auto;
      flex-grow: 1;

      @include media('<md') {
        flex-shrink: 1;
      }
    }

    &--search {
      padding-left: 43px;
    }
  }
  &__search-icon {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input-email {
    &::selection {
      background-color: #ffffff00;
      color: #808080;
    }
  }

  &__textarea {
    height: 78px;
    width: 100%;
    border: 1px solid $disabled;
    border-radius: $border-radius;
    font-size: 20px;
    padding: 12px 20px;
  }

  &__error {
    font-size: 16px;
    line-height: 20px;
    color: $error;
    margin-top: 7px;
    position: absolute;

    @include media('<md') {
      position: relative;
    }
  }

  &--input {

    #{$this}__show-button{
      position: absolute;
      right: 1px;
      top: 5px;
      height: 40px;
      font-size: 18px;
      line-height: 22px;
      color: $gray;
      padding: 9px;
      width: 87px;
      border-left: 1px solid $disabled;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;

      @include media('<md') {
        font-size: 14px;
        width: 58px;
      }
    }

    #{$this}__autofill{
      position: absolute;
      right: 0px;
      font-size: 14px;
      line-height: 22px;
      color: $gray;
      margin: 0 0 0 0;
      width: 163px;
      text-align: center;
      cursor: pointer;
      z-index: 2;
      height: 100%;
      top: 0;
      padding: 0 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        margin-right: 5px;

        @include media('<xl') {
          margin-right: 0px;
        }
      }

      @include media('<xl') {
        width: 25px;
        padding: 0px 10px 0px 4px;
        right: 0;
      }
    }
    #{$this}__autofill-button{
      position: absolute;
      right: 8px;
      top: 10px;
      bottom: 10px;
      z-index: 2;
      font-size: 13px;
      line-height: 16px;
      color: $brand-accent;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 0 0 0;
      text-align: center;
      cursor: pointer;
      padding: 2px 5px 0 5px;
      text-align: center;
      width: 73px;
      box-sizing: border-box;
      background-color: #F3E7FF;
      border-radius: 4px;
    }

    #{$this}__inside-button {
      position: relative;
      height: 40px;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      color: $brand-accent;
      padding: 9px;
      width: auto;
      border-left: 1px solid $disabled;
      text-align: center;
      cursor: pointer;
      flex-grow: 0;
      flex-shrink: 0;

      @include media('<md') {
        font-size: 12px;
      }

      &:disabled {
        color: $disabled;
      }
    }
  }
  &--password {
    .field__input {
      padding-right: 88px;

      @include media('<md') {
        padding-right: 59px;
      }
    }
  }
  
  &--filled {
    .field__input {
      padding-right: 173px;

      // @include media('<md') {
      //   padding-right: 159px;
      // }
    }
  }

  &--radio {
    position: relative;

    input[type="radio"] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .radio-card-big {
      max-width: 477px;
      width: 100%;
      border: 0.4px solid #BDBDBD;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 65px;

      @include media('<xl') {
        max-width: 422px;
        padding: 25px 29px;
      }

      @include media('<sm') {
        padding: 13px 29px;
      }

      @include media('<md-lg') {
        max-width: 477px;
      }

      &__image {
        @include media('<sm') {
          max-width: 115px;
        }

        &--pro {
          @include media('<sm') {
            max-width: 215px;
          }
        }
      }

      &__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        margin: 15px 0 5px;

        @include media('<sm') {
          font-size: 20px;
        }

        &--pro {
          margin: 27.5px 0 5px;
        }
      }

      &__text {
        max-width: 364px;
        width: 100%;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        text-align: center;

        @include media('<sm') {
          font-size: 16px;
        }
      }
    }
  }

  &--checkbox {
    position: relative;
    padding-bottom: 20px;

    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    @include media('<md') {
      padding-bottom: 10px;
    }
  }

  &--checked {
    .radio-button {
      box-shadow: 0 0 0 3px $brand-accent;
      &:hover {
        box-shadow: 0 0 0 3px $brand-accent;
      }

      &--card-new {
        background: #DFBFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: none;
        }
      }
    }

    .checkbox__marker {
      background-color: $brand-accent;
      border-color: $brand-accent;
    }

    .radio-card-big {
      color: $brand-accent;
      border: 2px solid $brand-accent;
    }
  }
}
.radio-button {
  $this: &;
  position: relative;
  border: 1px solid $gray-light;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include transition;
  min-height: 60px;
  padding: 12px 27px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 3px $light-accent;
  }

  &__with-icon {
    display: flex;
    align-items: center;
  }

  &__icon {
    @include media('<md') {
      max-width: 28px;
    }
  }
  
  &__with-icon-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $dark;
    margin-left: 5px;

    @include media('<md') {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__title {
    // font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $dark;

    @include media('<sm') {
      font-size: 18px;
    }
    // text-transform: uppercase;
  }

  &__price {
    font-family: $font-family-base;
    font-weight: 600;
    font-size: 45px;
    line-height: 55px;
    color: $brand-accent;
    margin: 24px 0 24px 0;
  }
  &__period {
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    color: $black;
    margin-left: 12px;

    @include media('<md') {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__price-by-month {
    font-family: $font-family-base;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    padding: 0 15px 20px;
  }

  &__head {
    background: #DFBFFF;
    width: 100%;
    font-family: $font-family-base;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000000;
    padding: 7px;
    border-radius: 8px 8px 0 0;
  }

  &__head-new {
    width: 150px;
    padding: 5px 0;
    background-color: $brand-accent;
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    border-radius: 10px 10px 0 0;
  }

  &--card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 0;
    border-radius: 10px;

    #{$this}__title {
      padding-top: 22px;
      font-weight: 600;

      @include media('<md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    #{$this}__price {

      @include media('<md') {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 0 7px;
        font-size: 35px;
        line-height: 42px;
      }
    }
  }

  &--card-new {
    max-width: 448px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 14px 0 0 40px;
    border-radius: 10px;
    align-items: flex-start;
    border: 0.4px solid #BDBDBD;

    &__with-head {
      border-radius: 0 10px 10px 10px;
    }

    #{$this}__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1em;

      @include media('<md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    #{$this}__content {
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        color: #4B2473;
      }
    }

    #{$this}__price-by-month {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      padding: 8px 0 8px;
    }

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border: 0.4px solid #BDBDBD;
    }

    &__checkbox {
      input {
        display: none;
      }
      &:before {
        content: "";
        position: absolute;
        width: 29px;
        height: 29px;
        border: 2px solid #DFBFFF;
        background-color: #ffffff;
        top: 45px;
        right: 30px;
        border-radius: 5px;
      }
      &--checked {
        &:after {
          content: "";
          position: absolute;
          width: 9px;
          height: 7px;
          border-left: 2px solid #0BCD9F;
          border-bottom: 2px solid #0BCD9F;
          top: 55px;
          right: 40px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &--middle-card {
    width: 100%;
    max-width: 462px;

    @include media('<md') {
      max-width: 100%;
    }
  }
}
.prefer-not-to-say {
  .radio-button {
    padding: 12px 20px;
  }
}
.checkbox {
  $this: &;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__marker {
    width: 23px;
    height: 23px;
    border-radius: $border-radius;
    box-sizing: border-box;
    border: 1px solid $black;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    color: $checkbox-color;
    position: relative;
  }

  &--button {
    border: 1px solid $gray-light;
    border-radius: $border-radius;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
    background-color: $light;
    overflow: hidden;

    @include media('<md') {
      padding: 13px 15px 13px 15px;
      //justify-content: center;
    }

    #{$this}__title {
      font-size: 20px;
      line-height: 24px;
      color: $black;

      @include media('<md') {
        font-size: 18px;
        line-height: 22px;
      }
    }
    #{$this}__icon {
      font-size: 18px;
      width: 18px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 12px;

      @include media('<md') {
        //display: none;
      }
    }

    &.checkbox--checked {
      border-color: $brand-accent;
      box-shadow: inset 0 0 0 1px $brand-accent;

      #{$this}__title {
        color: $brand-accent;
        font-weight: 600;
      }
    }
  }
}
.field-select {
  &.artists-preferences {
    padding-bottom: 24px;
    .select-options-container {
      min-height: 0;
    }
  }
  .option-wrapper {
    display: flex;
    justify-content: space-between;

    .label-wrapper {
      display: inline-block;

      .genre {
        display: inline;
        background-color: #EEEAF0;
        text-transform: uppercase;
        padding: 1px 10px 2px;
        border-radius: 10px;
        font-size: 13px;
        color: $brand-accent;
        margin-left: 5px;
      }
    }

    .add-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      min-width: 60px;
      filter: invert(26%) sepia(92%) saturate(7486%) hue-rotate(270deg) brightness(86%) contrast(92%);
      
      span {
        font-size: 16px;
        line-height: 20px;
        margin-left: 8px;
      }
    }
  }
  &--with-suggested {}

  &.songs-filter__select {
    width: 171px;
    position: relative;
  }

  &.profiles-filter__select {
    width: 171px;
    position: relative;
  }
}
.select-options-container {
  position: relative;
  padding-top: 17px;
  margin-bottom: -20px;
  min-height: 143px;

  @include media('<sm') {
    min-height: 229px;
  }

  .field {
    padding-bottom: 20px;
  }
}
.suggested-list {
  position: relative;
  padding: 12px 25px;
  border-radius: $border-radius;
  background-color: $gray-light2;
  margin-bottom: 35px;

  @include media('<md') {
    padding: 12px 18px;
  }

  &__title {
    font-size: 14px;
    line-height: 17px;
    color: $gray-text-color;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .field {
    padding-bottom: 20px;

    @include media('<md') {
      padding-bottom: 6px;
    }
  }
}
.subfield {
  //position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 35px;
  //z-index: 9;

  @include media('<md') {
    padding-bottom: 18px;
  }

  &__label {
    margin-right: 14px;
    font-size: 16px;
    line-height: 20px;
    color: $gray-text-color2;
    text-transform: uppercase;

    @include media('<md') {
      margin-right: 10px;
      min-width: 67px;
    }
  }

  .field {
    padding-bottom: 0px;
  }
}

.disliked-artist {
  color: #9E9E9E;
  border: 1px solid #9E9E9E;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: $font-family-base;
  font-size: 20px;
  position: relative;
  margin-bottom: 24px;

  .disliked-icon {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
}

.select-container {
  position: relative;

  &__search-icon {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.mood-radio-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 36px 0 0;

  @include media('<lg') {
    margin-top: 15px;
  }

  .mood-radio-btn {
    max-width: 246px;
    width: 100%;
    margin: 6px;

    @include media('<lg') {
      max-width: 276px;
    }

    @include media('<md') {
      max-width: 100%;
      margin: 6px 0;
    }

    .field {
      padding: 0px;

      .radio-button {
        height: 98px;
        background-color: #F4F5F8;

        @include media('<md') {
          height: auto;
          padding: 15px;
        }
      }
    }
  }
}

.mood-buttons-wrapper {
  max-width: 367px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  @include media('<md') {
    max-width: 100%;

    .btn {
      width: 130px;
    }
  }
  @include media('<sm') {
    flex-wrap: wrap;
    .btn {
      width: 100%;
    }
  }
}

.field-photo {
  $this: &;

  &__container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }
  &__description{
    width: 163px;
    height: 163px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #FAF5FF;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    svg.avatar {
      width: 163px!important;
      height: 163px!important;
    }

    &--view {
      width: 104px;
      height: 104px;

      svg.avatar {
        width: 104px!important;
        height: 104px!important;
      }

      #{$this}__description-text {
        font-size: 12px;
      }
    }
  }
  &__description-text {
    padding: 11px;
    font-size: 14px;
    line-height: 21px;
    color: #808696;
    text-align: center;
    margin: 0;
    text-align: center;
  }
  &__photo-image {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
  }
  &__input-container{
    width: 145px;
  }

  &__button-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: $brand-accent;
  }
  &__icon {
    margin-right: 4px;
  }
	&__error {
		margin-top: 10px;
		color: red;
		font-weight: bold;
	}
}
.photo-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__upload-container {
    position: relative;
  }

  &__input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;

    &::file-selector-button {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  &__upload, &__take-photo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__upload-icon {
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: $gray;
    font-weight: 600;
  }
}

.star-radio-btn {
  position: relative;

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  .star {
    background: 50% 0 no-repeat url("../../source/Star-inactive.svg");
    width: 54px;
    height: 50px;

    &:hover {
      background: 50% 0 no-repeat url("../../source/Star-hover.svg");
    }

    &--active {
      background: 50% 0 no-repeat url("../../source/Star-active.svg");

      &:hover {
        background: 50% 0 no-repeat url("../../source/Star-hover.svg");
      }
    }
  }
}

.add-profiles-card {
  max-width: 390px;
  width: 100%;
  border: 0.4px solid $gray-border;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 0 0;
  margin-bottom: 10px;
  height: 215px;
  position: relative;
  
  @include media('<sm') {
    padding: 20px 0 0;
    height: 190px;
  }

  &__title {
    font-weight: bold;
    color: #444444;
    font-size: 22px;
    line-height: 20px;
    text-align: center;

    @include media('<sm') {
      font-size: 18px;
    }
  }

  &__text {
    color: #444444;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    max-width: 263px;
    width: 100%;
    margin: 10px 0 20px;

    @include media('<sm') {
      font-size: 14px;
      max-width: 229px;
    }
  }

  &__footer-text {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #5F5F5F;
    margin: 7px 0 0;
  }
  
  &__button {
    font-size: 18px;
    height: 42px;
    min-width: 0;
    width: 142px;
    margin: 0px;
    position: absolute;
    bottom: 43px;
  }
}