.btn-select {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  

  &__button {
    flex: 1;
    @extend .btn;
    @extend .btn--secondary;
    color: $gray-border-darken;
    border: 2px solid $gray-border-darken;
    border-radius: 0;
    margin-bottom: 0;
    &--active {
      @extend .btn--primary;
      color: $light;
      border: 2px solid $brand-accent;
    }
    &--first {
      border-top-left-radius: $border-radius-button;
      border-bottom-left-radius: $border-radius-button;
    }
    &--last {
      border-top-right-radius: $border-radius-button;
      border-bottom-right-radius: $border-radius-button;
    }
  }
}