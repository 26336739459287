.start-wrapper {

  display: flex;
  width: 100%;
  flex-grow: 1;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;


  .forgot-password {
    font-size: 16px;
    line-height: 22px;
    color: $brand-accent;
    text-align: center;
    margin-bottom: 0;
    font-weight: 400;
    text-decoration: none;
  }

  .join-now-block {
    align-self: center;
    display: flex;


    text-align: center;
    margin-bottom: 12px;
    text-align: center;

    &.gap-top-medium {
      margin-top: 20px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $text-color-dark;
      margin-bottom: 0;
      font-weight: 400;
    }

    .join-now {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $text-color-dark;
      text-align: center;
      margin-left: 7px;
    }
  }

  .phone-layout {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    min-height: 100vh;


    @include media('<sm') {
      padding: 0px 22px;
      padding-top: 85px;
    }

    @media (max-width: 375px) {
      padding-top: 40px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50%;
      filter: blur(100px);
      background-image: linear-gradient(0deg, rgba(211, 195, 228, 0.0692) 0%, rgba(211, 195, 228, 0.0692) 0.01%, rgba(83, 20, 149, 0.27) 72.92%);
    }

    &--pro {
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 60%;
        filter: blur(150px);
        background-image: linear-gradient(0deg, rgba(174, 238, 223, 0.19) 0%, rgba(11, 205, 159, 0.57) 73%);
      }
    }

    &__switcher {
      margin-bottom: 50px;
      width: 100%;

      button {
        font-size: 18px;
      }
    }

    h1 {
      font-size: 32px;
      line-height: 56px;
      margin-bottom: 20px;
      color: $brand;
      text-align: center;
      margin-bottom: 8px;


    }

    &__description {
      width: 90%;
      font-size: 20px;
      line-height: 24px;
      color: $text-color-dark;
      text-align: center;
      margin-bottom: 0;
      text-align: center;
      margin-bottom: 5px;

      &--colored {
        color: $brand-accent;
        font-weight: 700;
      }

      &.gap-bottom {
        margin-bottom: 40px;
      }

      &.gap-bottom-medium {
        margin-bottom: 15px;
      }

      &.gap-top-large {
        margin-top: 60px;
      }

      &.gap-top-medium {
        margin-top: 20px;
      }
      &.gap-top {
        margin-top: 40px;
      }

      a {
        color: $text-color-dark;
        font-weight: 700;
      }
      .join-now {
        font-weight: 400;
      }
    }

    &__button {
      border-radius: $border-radius-button;
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__image {
      position: absolute;
      bottom: 0;




      @media (max-width: 375px) {
        height: 200px;
      }



    }






  }

  .table-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    height: 100%;
    align-items: center;
    flex-grow: 1;

    .forgot-password {
      font-weight: 700;
    }

    h1 {
      font-size: 52px;
      line-height: 56px;
      margin-bottom: 20px;
      color: $brand;
      text-align: center;
      margin-bottom: 16px;
      letter-spacing: -2%;


    }

    &__description {
      width: 85%;
      font-size: 16px;
      line-height: 24px;
      color: $text-color-dark;
      text-align: center;
      margin-bottom: 0;
      text-align: center;

      &--colored {
        color: $brand-accent;
        font-weight: 700;
      }

      &.gap-bottom {
        margin-bottom: 40px;
      }

      &.gap-top-large {
        margin-top: 60px;
      }

      &.gap-top-medium {
        margin-top: 20px;
      }
      &.gap-top {
        margin-top: 40px;
      }

      a {
        color: $text-color-dark;
        font-weight: 700;
      }
    }

    &__button-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;

    }

    &__button {
      border-radius: $border-radius-button;
      font-size: 18px;
      margin-bottom: 12px;

      &--wide {
        width: 100%;
      }

    }

    .left-block,
    .right-block {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      height: 100%;
      padding-top: 120px;
      align-items: center;
      max-width: 440px;
      margin: 0 auto;
      position: relative;


    }

    .left-block {
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 25%;
        border-radius: 300px;
        filter: blur(75px);
        background-image: linear-gradient(0deg, rgba(211, 195, 228, 0.0692) 0%, rgba(211, 195, 228, 0.0692) 0.01%, rgba(83, 20, 149, 0.27) 72.92%);
      }
    }

    .right-block {
      &::after {
        content: '';
        display: block;
        border-radius: 300px;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 25%;
        filter: blur(150px);
        background-image: linear-gradient(0deg, rgba(174, 238, 223, 0.19) 0%, rgba(11, 205, 159, 0.57) 73%);
      }
    }

    &__image {
      position: absolute;
      height: 300px;
      bottom: 0px;
    }

  }

}