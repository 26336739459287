.music-types-menu {
  margin-left: -7px;
  margin-right: -7px;

  &__item {
    position: relative;
    margin-bottom: 18px;
    padding-left: 7px;
    padding-right: 7px;
  }
  &__link {
    text-decoration: none;
    display: block;
    position: relative;
    color: $dark;
    height: 100%;
    width: 100%;
    text-align: inherit;
  }
}

.music-type {
  $this: &;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border: 3px solid $light;
  border-radius: 10px;
  background-color: $light;
  padding: 20px 28px;
  min-height: 100%;
  transition: 0.3s;

  @include media('<xl') {
    padding: 19px 24px;
  }

  &__image-container {
    height: 225px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 11px;

    @include media('<xl') {
      height: 150px;
    }
  }
  &__image {
    max-height: 100%;
  }
  &__title-container {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: $dark;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media('<xl') {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__title {}
  &__label {
    margin-left: 9px;
  }
  &__description {
    font-size: 14px;
    line-height: 22px;

    @include media('<xl') {
      font-size: 12px;
    }
  }
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  }

  &--relax {
    #{$this}__label {
      background-color: $relax;
    }

    &:hover {
      border: 3px solid $relax;
    }
  }
  &--reminisce {
    #{$this}__label {
      background-color: $reminisce;
    }

    &:hover {
      border: 3px solid $reminisce;
    }
  }
  &--energise {
    #{$this}__label {
      background-color: $energise;
    }

    &:hover {
      border: 3px solid $energise;
    }
  }
}

.resident-name {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  align-content: center;
   b {
    align-self: center;
    margin-left: 5px;
   }
}

.table__center {
  text-align: center;
}

.table__right {
  text-align: right;
}

.brand-color {
  color: $brand-accent;
}