.profiles-bar {
  margin: 0 0 33px 0;
}
.profiles-filter {
  display: flex;
  align-items: center;

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #939393;
    margin-right: 15px;
  }
}
.add-profiles-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  padding: 30px;

  @include media('<md') {
    padding: 30px 0;
  }
}

.add-profiles-title {
  font-size: 22px;
  line-height: 22px;
  color: #444444;
  font-weight: bold;
  margin-bottom: 6px;
}

.add-profiles-text {
  font-size: 14px;
  line-height: 22px;
  color: #444444;
  margin-bottom: 34px;
}

.profile-card {
  position: relative;
  margin-bottom: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__photo-container {
    width: 97px;
    height: 97px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 25px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #E8D2FF;
    font-size: 44px;
    font-weight: bold;
    color: #9A7ABC;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__photo-image {
    object-fit: cover;
  }

  &__data {
    width: calc(100% - 122px);
  }
  &__title {
    font-size: 12px;
    line-height: 22px;
    color: #B4B5BA;
    font-weight: 600;
    margin-bottom: 3px;
  }
  &__name {
    font-size: 16px;
    line-height: 26px;
    color: $dark;
    font-weight: bold;
    margin-bottom: 7px;
  }
  &__data-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__data-label {
    width: 100px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 21px;
    color: $player-bg;
    font-weight: bold;
  }
  &__data-value {
    width: calc(100% - 100px);
    font-size: 14px;
    line-height: 21px;
    color: $player-bg;
  }
}

// residents
.residents-list {
  position: relative;
  margin: 0 -9px;
}

.resident-card {
  position: relative;
  margin-bottom: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 9px;
  border-radius: 4px;
  transition: 0.3s;
  width: 100%;
  text-align: left;

  &__photo-container {
    width: 62px;
    height: 62px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 2px;
    overflow: hidden;
  }
  &__photo-image {
    object-fit: cover;
  }

  &__data {
    width: calc(100% - 72px);
  }
  &__name {
    font-size: 18px;
    line-height: 22px;
    color: $dark;
    font-weight: bold;
    margin-bottom: 1px;
  }
  &__res-id {
    font-size: 12px;
    line-height: 21px;
    color: $player-bg;
  }
  &__res-id-label {
    font-weight: 600;
  }
  &__played {
    font-size: 12px;
    line-height: 21px;
    color: $gray-text-color2;
    font-style: italic;
  }

  &:hover {
    background-color: #F2E6FF;
  }
}

.btn--resident-card {
  border: none;
  height: auto;
  font-weight: normal;
  padding: 9px;
}

.search-resident-container {
  position: relative;
  width: 225px;
  max-width: 100%;

  @include media('<md') {
    width: 100%;
  }
}

.profile-card-accordion {
  $this: &;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__photo-container {
    padding-right: 15px;
    position: relative;
  }
  &__data-container {
    display: block;

    .btn {
      margin-bottom: 0;
    }
  }
  &__name {
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: $player-bg;
    font-weight: bold;
  }
  &__id {
    font-size: 14px;
    line-height: 22px;
    color: #A8A8A8;
    font-weight: normal;
    margin-left: 7px;
  }
  &__membership {
    display: block;
    font-size: 13px;
    line-height: 22px;
    color: $player-bg;
  }
  &__membership-title {
    color: #828282;
    font-weight: bold;
    margin-right: 12px;
  }
  &__invited {
    font-size: 13px;
    line-height: 22px;
    color: #8B8B8B;
  }

  &__data-container-disabled {
    #{$this}__name {
      color: #8B8B8B;
    }
    #{$this}__id {
      color: #8B8B8B;
    }
    #{$this}__membership {
      color: #8B8B8B;
    }
    #{$this}__membership-title {
      color: #8B8B8B;
    }
  }
}

.filter-bottom-bar {
  position: fixed;
  z-index: 10;
  background-color: $light;
  left: -17px;
  right: -17px;
  bottom: 0px;
  height: 61px;
  padding: 9px 22px;

  .profiles-filter__title {
    font-size: 14px;
    line-height: 17px;
    color: $text-color;
    font-weight: bold;
    margin-right: 18px;
    white-space: nowrap;
  }

  .field-select.profiles-filter__select {
    width: 100%;
  }

  .profiles-filter__select {
    .react-select__control {
      height: 42px;
      border-color: #C4C4C4;
    }
  }
}
.profiles-table-item {

  &__name {
    display: block;
  }

  &__id {
    display: block;
    font-size: 14px;
    line-height: 22px;
  }
}

.resident-play-button {
  margin-bottom: 23px;
}

.archived-button {
  font-weight: 500;
  .icon {
    margin-right: 13px;
    path {
      fill: #7A23D3;
    }
  }
}

.archieve-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4B2473;

  .icon {
    width: 25px;
    margin-right: 0;
    height: 25px;
  }
}