.btn {
  $this: &;
  @extend %btn-flex;
  @include transition;
  display: inline-flex;
  padding: 12px 27px;
  height: 56px;
  min-width: 176px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;
  background-color: transparent;
  overflow: hidden;
  @include line-height(29px, 24px);
  border: 1px solid $disabled;
  color: $text-color;
  border-radius: $border-radius;
  cursor: pointer;
  text-decoration: none;

  @include media('<md') {
    margin-bottom: 14px;
    width: 100%;
  }

  @include media('<sm') {
    min-width: 0;
    padding: 12px 15px;
  }

  &:disabled {
    border: 1px solid $disabled;
    background-color: $disabled;
    color: $light;
    cursor: auto;

    #{$this}__icon {
      fill: $light;
    }
  }

  &__icon {
    fill: $text-color;
    margin-right: 8px;

    &--refresh {
      font-size: 12px;
    }
  }

  &__icon-main-color {
    fill: $brand-accent;
  }

  &--active {
    background-color: $brand-accent;
    color: $light;
    border-color: $brand-accent;
  }

  &--primary {
    border: 1px solid $brand-accent;
    background-color: $brand-accent;
    color: $light;

    #{$this}__icon {
      fill: $light;
    }
  }
  &--secondary {
    border: 1px solid $brand-accent;
    color: $brand-color;

    #{$this}__icon {
      fill: $brand-color;
    }
  }
  &--accent {
    background-color: #F8F1FF;
    border-color: #F8F1FF;
    color: $brand-accent;
  }
  &--small {
    font-size: 14px;
    line-height: 17px;
    padding: 12px 15px;
    height: auto;
    min-width: 0;
  }
  &--middle {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 16px;
    height: auto;
    font-weight: 700;
    min-width: 0;
  }
  &--minimal {
    padding: 0;
    border: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #7019C8;
    min-width: 0;
    height: auto;

    @include media('<xl') {
      width: auto;
      font-size: 15px;
      line-height: 17px;
    }

    @include media('<lg') {
      width: auto;
      font-size: 14px;
      line-height: 17px;
    }

    &:disabled {
      background-color: transparent;
      border: none;
      color: #8B8B8B;
    }
  }
  &--relax {
    background-color: $relax;
    border-color: $relax;

    &.btn--secondary {
      background-color: transparent;
      border: 1px solid $relax;
      color: $relax;
    }
  }

  &--reminisce {
    background-color: $reminisce;
    border-color: $reminisce;

    &.btn--secondary {
      background-color: transparent;
      border: 1px solid $reminisce;
      color: $reminisce;
    }
  }

  &--energise {
    background-color: $energise;
    border-color: $energise;

    &.btn--secondary {
      background-color: transparent;
      border: 1px solid $energise;
      color: $energise;
    }
  }

  &--preferences-navigation {
    min-width: 117px;

    @include media('<md') {
      min-width: 87px;
    }
  }

  &--logout {
    background-color: #FBF8FF;
    margin-top: 17px;
  }

  &--reject {
    color: #D64242;
  }

  &--full-width {
    width: 100%;
  }

  &.table__action {
    margin-bottom: 0;

    @include media('<md') {
      margin-bottom: 0;
    }
  }
}
.btn-back {
  $this: &;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0 0 10px;

  &__icon {
    font-size: 6px;
    margin-right: 7px;
  }

  &--small {
    #{$this}__icon {
      font-size: 6px;
      margin-right: 7px;

      @include media('<md') {
        font-size: 9px;
        margin-right: 14px;
      }
    }
  }

  &--purple {
    color: #7A23D3;
    .btn-back__icon {
      fill: #7A23D3;
    }
  }
}
.social-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 154px;
  padding: 14px 22px;

  @include media('<xl') {
    min-width: 103px;
    padding: 6px 12px;
  }

  @include media('<md') {
    width: auto;
    padding: 5px 10px;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 28px;
    height: 28px;

    @include media('<xl') {
      width: 22px;
      height: 22px;
    }
  }
  &__icon {}
  &__text {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;

    @include media('<xl') {
      font-size: 14px;
      line-height: 17px;
    }

    @include media('<sm') {
      font-size: 12px;
      line-height: 14px;
    }
  }
}